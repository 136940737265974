/**=====================
  36. General CSS Start
==========================**/
//Custom css Start
html {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  font-size: $body-font-size;
  overflow-x: hidden;
  font-family: $font-montserrat;
  color: $theme-body-font-color;
  background-color: $light-background;
}

ul {
  padding-left: $ul-padding-left;
  list-style-type: none;
  margin-bottom: 0;
  padding-right: $ul-padding-right;
}

a {
  color: var(--theme-default);
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.btn-link {
  text-decoration: none;
}

*a {
  color: var(--theme-default);
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

*.btn:focus {
  box-shadow: $btn-focus-box-shadow !important;
}

h6 {
  font-weight: 600;
}

p {
  font-size: $paragraph-font-size;
  line-height: $paragraph-line-height;
  letter-spacing: $paragraph-letter-spacing;
  margin-bottom: 0;
}

span {
  font-weight: 500;
}

.dropdown-item {

  &:active,
  &.active {
    background-color: var(--theme-default);
  }
}

hr {
  background-color: $light-gray;
  opacity: 1;
}

.theme-form {
  .col-form-label {
    color: $semi-dark;
  }
}

.form-label {
  color: $semi-dark;
  font-weight: 500;
}

code {
  color: $code-tag-color !important;
  background-color: $code-tag-bg-color;
  padding: $code-tag-padding;
  margin: $code-tag-margin;
  border-radius: $code-tag-border-radious;
}

blockquote {
  border-left: $blockquote-border;
  padding: $blockquote-padding;
}

blockquote {
  &.text-center {
    border: none;
    padding: $blockquote-padding;
  }
}

blockquote {
  &.text-end {
    border-left: none;
    border-right: $blockquote-border;
    padding: $blockquote-padding;
  }
}

:focus {
  outline: none;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

svg {
  vertical-align: baseline;
}

input {
  &:focus {
    outline-color: $transparent-color;
  }
}

.font-nunito {
  font-family: $font-nunito, $font-serif;
}

.font-roboto {
  font-family: $font-roboto;
}

.font-rubik {
  font-family: $font-rubik, $font-serif;
}

.media-widgets {
  .media-body {
    margin-bottom: 30px;
  }
}

.rating-star {
  display: flex;
  align-items: center;

  li {
    i {
      color: $yellow;
    }
  }
}

.table> :not(:first-child) {
  border-top: none;
}

.table {
  margin-top: 0;
}

.page-header {
  .breadcrumb {
    justify-content: flex-end;
  }
}

// setting rotation
@keyframes rotateInSetting {
  0% {
    transform-origin: center;
    transform: rotate(-200deg);
  }

  to {
    transform-origin: center;
    transform: none;
  }
}

.setting-primary,
.setting-secondary,
.setting-white {
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;

  svg {
    width: 15px;
    stroke-width: 2.5px;
    animation: rotateInSetting 1.5s ease infinite;
  }
}

.setting-primary {
  background-color: rgba($primary-color, 0.1);

  svg {
    color: var(--theme-default);
  }

  i {
    color: var(--theme-default);
  }

  &:hover {
    background-color: var(--theme-default);

    svg {
      color: $white;
    }

    i {
      color: $white;
    }
  }
}

.setting-secondary {
  background-color: rgba($secondary-color, 0.1);

  svg {
    color: var(--theme-secondary);
  }

  i {
    color: var(--theme-secondary);
  }

  &:hover {
    background-color: var(--theme-secondary);

    svg {
      color: $white;
    }

    i {
      color: $white;
    }
  }
}

.light-font {
  color: $theme-body-sub-title-color;
}

.setting-white {
  background-color: rgba($white, 0.1);

  svg {
    color: $white;
  }

  i {
    color: $white;
  }

  &:hover {
    background-color: $white;

    svg {
      color: var(--theme-default);
    }

    i {
      color: var(--theme-default);
    }
  }
}

.modal-title {
  font-weight: 600;
  color: $theme-body-font-color;
}

button.close,
button[aria-label="Close"] {
  background: unset;
  border: none;

  span {
    font-size: 24px;
    color: $dark-gray;
  }
}

.setting-bg-primary {
  background-color: rgba($primary-color, 0.1);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;

  svg {
    width: 15px;
    color: var(--theme-default);
    stroke-width: 2.5px;
    animation: rotateInSetting 1.5s ease infinite;
  }
}

.ui-list {
  .list-group-item {
    background-color: $light-background;
    padding: 0.5rem 1rem;
    border: 1px solid $light-gray;
  }

  .list-group-item.active {
    background-color: var(--theme-default) !important;
    border-color: var(--theme-default) !important;
    color: $white;
  }

  .list-group {
    i {
      margin-right: 10px;
    }

    img {
      height: 40px;
      width: 40px;
      margin-right: $list-group-margin;
    }
  }
}

#mycalendartest {
  .fc-daygrid {
    table {
      margin-top: 0;
    }
  }
}

//Custom css End
//Typography css start
.typography {

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    margin: 20px 0;
    margin-top: 0;
    color: $theme-body-font-color;

    &:last-child {
      margin-bottom: 0;
    }
  }

  small {
    padding-left: $small-tag-padding;
    color: $small-tag-color;
  }
}

pre {
  background-color: $pre-tag-bg-color;
  padding: $pre-tag-padding;
}

a {
  &:hover {
    text-decoration: none;
    color: var(--theme-default);
  }
}

.typography {
  .blockquote-footer {
    background-color: $light-background;
  }
}

//Typography css end
.owl-theme {
  .owl-dots {
    .owl-dot {
      span {
        background: lighten($primary-color, 20%);
      }

      &.active,
      &:hover {
        span {
          background: var(--theme-default);
        }
      }
    }
  }

  .owl-nav {
    &.disabled {
      &+.owl-dots {
        margin-bottom: -10px;
        margin-top: 25px;
      }
    }
  }
}

// modal page
.modal {
  .modal-body {
    .card {
      box-shadow: none !important;
    }
  }
}

.modal-dialog {
  .modal-content {
    .modal-body {
      p {
        a {
          margin-right: 0;
        }
      }
    }
  }
}

.clipboaard-container {
  p {
    font-weight: 600;
  }

  h6 {
    line-height: 1.8;
  }
}

// index page notify
.alert-theme {
  button {
    top: 24px !important;
    right: 30px !important;
    color: var(--theme-default);
  }

  span {
    +span {
      +span {
        border-left: 5px solid var(--theme-default);
        background-color: $white;
        border-radius: 4px;
        box-shadow: 0 2px 14px -3px rgba($black, 0.2);
      }
    }
  }

  i {
    padding: 20px;
    padding-right: 0 !important;
    margin-right: 10px !important;
  }

  .progress {
    margin-top: -2px !important;
    height: 2px;

    .progress-bar {
      background-color: var(--theme-default);
    }
  }
}

.alert-copy {
  border: 1px solid var(--theme-default);
  line-height: 1;
  display: inline-block;
  width: 300px;
  color: $white;
  background-color: var(--theme-default);
  box-shadow: 3px 3px 5px 0 #9b9b9b;

  .close {
    box-shadow: none;
    color: $white;
    line-height: 1.4;
    font-weight: 100;
  }
}

.ace-editor {
  height: 400px;
  width: 100%;
}

.editor-toolbar {
  width: 100% !important;
}

.CodeMirror {
  top: 0 !important;
}

.border {
  border: 1px solid $light-gray !important;
}

// rangeslider
.irs-from,
.irs-to,
.irs-single {
  background: var(--theme-default);
}

// medaia //
.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.row {
  >div {
    position: relative;
  }
}

[class*="col-"] {
  position: relative;
}

.cke_top {
  background-color: $light-background;
}

.cke_wysiwyg_frame,
.cke_wysiwyg_div {
  background-color: $light-background;
}

// modal page css //
.modal-header,
.modal-footer,
.modal-content {
  .btn-close {
    position: absolute;
    top: 30px;
    right: 25px;
  }
}

.btn-close {
  &:focus {
    box-shadow: none;
  }
}

// photoswipe //
.my-gallery {
  &.gallery-with-description {
    img {
      border-color: $light-gray !important;
    }
  }
}

/**=====================
  36. General CSS Ends
==========================**/

/**=====================
  000. SCONCEPT Custom CSS Start
==========================**/

//Hide CAPTCHA
.grecaptcha-badge {
  opacity: 0;
  z-index: -999;
  position: absolute;
}

//Hide Theme Customiser 
#c-pills-tab {
  display: none;
}

.main-menu {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: $main-header-right-padding;
  border-right: 1px solid $light-gray;
}

.toggle-sidebar {
  display: none;
}

.toggle-sidebar:hover {
  background: #fff !important;
  cursor: pointer;

}

.mobile-client-nav {
  position: fixed;
  border-bottom: none;
  top: 0px;
  z-index: 8;
  height: 100%;
  line-height: inherit;
  width: 0px;
  text-align: left;
  transition: 0.3s ease;
  right: 0px;
  transition: 0.5s;
  border-left: 1px solid #e6edef;
  border-right: unset;
  background: rgb(19 30 44 / 95%);
}


.mobile-client-nav.mobile-nav-open {
  width: 200px;
}

.mobile-nav-client-button {}

.mobile-nav-client-button:hover {}

.close-mobile-nav-client {
  color: #f4af17;
  padding: 10px;
  margin: 10px;
  border-radius: 4px;
  margin: 10px 0px 10px 150px;
  width: fit-content;
  height: fit-content;
  text-align: right !important;
  right: 0px;
  top: 20px;
  cursor: pointer;
}


.close-mobile-nav-client:hover {
  background: #fff;
}


.mobile-client-nav nav div.main-navbar .sidebar-main-title {
  color: #fff;
  margin: 10px 10px 10px 10px;
  padding: 10px;
  font-size: 20px;
}

.mobile-client-nav nav div.main-navbar .sidebar-main-title:hover {
  background: transparent !important;
}

.mobile-client-nav nav div.main-navbar #sidebar-menu ul li {
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  border-radius: 4px;
}

.mobile-client-nav nav div.main-navbar #sidebar-menu ul li:hover {
  background: #fff;
}


.mobile-client-nav nav div.main-navbar #sidebar-menu ul li a:focus {
  color: #fff;
}

.hideThis {
  display: none !important;
}

img:not([src]) {
  // visibility: hidden !important;
}

#c-pills-tab {
  // display: none !important;
}

.customizer-contain {
  // display: none !important;
}

.client-menu li {
  background: none !important;
}


.client-menu li a {
  color: #959595;
  background-color: none;
}

.client-menu li a:focus {
  color: #fff !important;
}


.form-control-max-park {
  background: #fafafa !important;
  min-height: 50px !important;
}

.btn-maxpark {
  color: #000 !important;
  font-size: 1.2em !important;
  letter-spacing: 0px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /*box-shadow: 0px 6px 12px -6px rgb(24 39 75 / 12%), 0px 8px 24px -4px #1c24315e; */
  border-radius: 4px;
  font-weight: 500 !important;
  font-family: 'Roboto';
  font-style: normal;
  line-height: 20px;
}

.btn-maxpark:hover {
  background: #f4a700 !important;
  color: #fff !important;

}

.btn-fadedgray {
  background: #f9f9f9 !important;
  color: #cbcbcb !important;
}

.btn-fadedgray:hover {
  background: #e8e8e8 !important;
  color: #cbc8c8 !important;
}

.reverse-x {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.f-color-white {
  color: #fff !important;
}

.f-color-fadedgray {
  color: #cbcbcb !important;
}

.f-bg-white {
  background: #fff !important;
}

.f-bg-gray {
  background: #f8f8f8 !important;
}

.f-bg-fadegray {
  background: #fdfdfd !important;
}

.f-color-primary {
  // color:#f4af17 !important;
  color: $primary-color;
}

.f-color-secondary {
  color: #212529 !important;
}

.f-color-light-text {
  color: #aeb6b9 !important;
}

.min-h-400 {
  min-height: 400px;
}

.min-h-700 {
  min-height: 700px;
}

.min-w-200 {
  min-width: 200px;
}

.max-w-1800 {
  max-width: 1800px;
}

.l-s-0 {
  letter-spacing: 0px;
}

.no-border {
  border: 0px !important;
}

.no-background {
  background: none !important;
}

body {
  font-family: "Roboto" !important;
}

.top-15 {
  top: 15px;
}

.right-85p {
  right: 85%;
}

.h-15 {
  height: 15px;
}

.h-20 {
  height: 20px;
}

.h-35 {
  height: 35px;
}

.h-300 {
  height: 300px !important;
}

.h-350 {
  height: 350px !important;
}

.h-650 {
  height: 650px !important;
}

.w-200 {
  width: 200px !important;
}

.w-250 {
  width: 250px !important;
}

.m-0px-auto {
  margin: 0px auto;
}

.r-0 {
  right: 0% !important;
}

.l-0 {
  left: 0% !important;
}

.t-50 {
  top: 50px;
}

.float-right {
  float: right;
}

.m-t-n-30 {
  margin-top: -30px;
}

.datePriceUp {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: #999;
  text-align: right;
}

.datePriceHasPassed {
  color: #dcdcdc !important;
}

.payment-form {}

.payment-form h1 {
  margin-left: 15px;
}

.form-container {
  margin-bottom: 100px;
}

.paycard-container {}

.p-10 {
  padding: 10px !important;
}


.modalHide {
  display: none !important;

}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.fit-content {
  width: fit-content;
}

.icon-button-fix {
  position: relative;
  left: -3px;
  top: 3px;
}

/**=============================================================================================================================================================================================================================
  37. Custom Css Starts SCONCEPT
  *
  *
  *PAGE SPECIFIC
  *
  *
==============================================================================================================================================================================================================================**/
.black-wheel {
  fill: #191919 !important;
}

.background-maxpark {
  background: url('../../../assets/images/banner/maxpark-bg.jpg');
  background-size: cover;
}

// Header-ClientWebiste //
.header-client {}

.nav-container-client {
  background: #131e2c !important;
  width: auto !important;
}


.nav-container-client .main-header-left {
  border: 0px !important;
}

.nav-container-client ul {
  font-size: 20px !important;
}

.landing-slider {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-color: rgba(0, 0, 0, 0.5);
  height: 500px !important;

}

.landing-home-contain {
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 2;
  position: relative;
  padding: 40px;

}

.landing-slider-headline {
  position: absolute;
  padding: 40px;
}

.landing-slider-headline h1 {
  font-weight: bold;
}

.landing-slider-headline p.sub-content {
  color: #fff !important;
  padding: 40px 0px 40px 0px;
  font-size: 16px;
  font-weight: 400;
  font-family: "Rubik", sans-serif;
  margin-bottom: 30px;
  color: #999;

}

.landing-location{
  background:#131e2c  !important;
  border:0px !important;
  color:#F6F6F6 !important;
}

.title-section {
  text-align: center;
}

.title-section h2 {
  line-height: 1.70;
  font-size: 1em !important;
  letter-spacing: 0px;
}

.icon-main-page {
  color: rgba($primary-color, 1);
  font-size: 4.5em;
}

.mission {
  background: #fff !important;
}

.mission-card {
  text-align: center !important;
  color: #212529 !important;
  background: transparent !important;
  border: 0px !important;
}

.mission-card i {
  font-size: 4em !important;
  padding: 20px;
  color: #f4af17 !important;
}


.mission-card h3 {
  font-size: 1.15em !important;
  text-align: center !important;
  color: #212529 !important;
  font-weight: 700;
}

.mission-card p {

  color: #212529 !important;
}

.services {
  background: #e8e8e8 !important;
}

.services h2 {
  font-size: 20px;
  font-weight: 700;
  color: #212529;
  margin-bottom: 0;
  line-height: 1.2;
  text-align: center;
  padding: 40px;
}

.services-container {
  max-width: 1800px;
  margin-top: -300px;
  background: #fff;
  position: relative;
}

.services-text p {
  font-size: 1.5em;
}

.bg-carbonblack {
  background-color: #212529 !important;
  color: #fff;
  border-radius: 6px !important;
  min-height: 280px;
}

.list-carbonblack li {
  margin-top: 15px;

}

.list-carbonblack li i {
  color: #f4af17 !important;
  padding: 3px 0px 0px 3px;
  margin-right: 5px;

}

.list-item {

  display: inline-flex;
}

.section-nos-services {
  padding: 50px;
}

.quote-form {
  padding-top: 50px;
}

.quote-form-first-row {
  margin-top: 50px;
}


.form-control,
.form-select {
  background-color: #fff;
  border-color: #E4E4E7;
  color: #717171;
  font-size: 14px;
}

.form-control {
  -webkit-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #E4E4E7;
  border-radius: 0.25rem;
  color: #212529;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  width: 100%;
}


.loading-message {}

.paymodal-loading-container {
  /*position: absolute !important;
  left:27%;*/

}

.loading-paymodal {
  min-height: 400px !important;
  position: inherit !important;
  display: inherit !important;
}


.imgPopUp {
  width: 100%;
  min-width: 400px;
  max-height: 350px;
  min-height: 400px;
  position: relative;
  position: absolute !important;
  background: #19212e !important;
  border: 2px solid #1b2330 !important;
  overflow: hidden;
}

.img-view {
  width: 300px;
  height: auto;
  margin: 0px auto;

}


/**** Payer *****/

.searchbox-container {
  min-height: 900px !important;

}

.searchbox-bg {
  height: fit-content;
  min-height: 600px;
  background-size: cover;
  margin: 20px;
  //position: relative;
  //top:150px;
  //left:10%;
}

.search-banner {
  min-height: 100vh;
  background-size: cover !important;
  padding-top: 20px;
}

.search-banner input {
  font-size: 20px !important;
  height: 60px !important;
  background: #fff !important;
}

.search-banner-error {
  border: 2px solid red !important;

}

.search-banner button {
  font-size: 20px !important;
  background: #f4af17 !important;

}

.search h1 p {
  text-color: #fff !important;
}

.searchInput {
  font-size: 4vw !important;
}

.searchTicket-box .form-inline {
  background-color: transparent;
  width: 500px;
  padding: 12px 50px;
  border-radius: 10px;
  margin-top: 10px;
  position: relative;
  font-size: 2.2em !important;
}

.searchTicket-search {
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  left: 5%;
}

.search-control {
  font-size: 20px !important;
  font-stretch: condensed;
  min-height: 50px !important;
}

.searchTicket-form {
  font-size: 2.2em !important;
  display: -webkit-inline-box;
}

.searchTicket-search div h1 {
  color: #fff !important;
  margin-bottom: 30px;
  font-weight: 700;
  font-stretch: expanded;
}

.searchTicket-search div p {
  font-weight: 500;
  color: #fff !important;
  margin-bottom: 30px;
  font-size: 16px;
}

.button-search {
  margin-left: 20px;
  padding: 11px;
  margin-top: -2px;
}

.footer-client {
  background: #151f2b !important;
  padding: 40px;
}


.footer-bg-color {
  background: #151f2b !important;
 
}

.footer-container {
  max-width: 1800px;
}

.footer-client .logo-footer {

  max-height: 40px;
  width: fit-content;

}

.list-footer {
  color: #717171 !important;
}

.footer-link {
  color: #717171 !important;
}

.footer-link:hover {
  color: rgba($primary-color, 1) !important;
}

.footer-row-right {

  text-align: right !important;
}

.footer {
  /* text-align: center; */
  background-color: #151f2b;
  border-top: 2px;
  bottom: 0;
  left: 0;
  margin-left: 230px !important;
  padding: 15px;
}

.header-paymodal {
  font-weight: 900 !important;
  text-align: center;
  color: #999 !important;
}


.card-paymodal p {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: #999;
}



.paymodal-container {
  padding: 0px !important;
  border-radius: 10px !important;
  transition: .8s ease;
  max-width: 400px;
  min-width: 300px;
  //position:relative;
  //right:-12%;
  opacity: 0;
  //animation-delay: 3s;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #F6F6F6;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.error-form {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.975em;
  color: #dc3545;
}

.error-form-success {
  color: #4caf50 !important;
}

.wrapper {
  margin: 30px;
  padding: 30px;
  background: #fff;
  width: 360px;
  height: 640px;
  display: flex;
  flex-direction: column;
}

.wrapper-cell {
  display: flex;
  margin-bottom: 30px;
}

// Animation
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }

  100% {
    background-position: 468px 0
  }
}


/*************** LOADING PAYMODAL ANIM ********************************* */

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #F6F6F6;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

// Page Elements
.image-loading {
  height: 100px;
  width: 100px;
  //  background: #F6F6F6;
  @extend .animated-background;
}

.text {
  margin-left: 20px
}

.text-line-loading {
  height: 10px;
  width: 100px;
  //  background: #F6F6F6;
  margin: 4px 0;
  @extend .animated-background;
}



.paymodal-container .card-header {

  margin-bottom: 20px !important;
  border-radius: 15px !important;
}


.paymodal-container .card-header h5 {
  text-align: center !important;
  color: #919191 !important;
  font-size: 28px !important;
}

.fslightbox-container {
  overflow: hidden;
}

img.fslightbox-source {
  padding: 40px 0px 40px 0px;
}

.paymodal-img {
  height: 150px !important;
  width: 150px !important;
  margin-left: 15px;
  max-width: 100% !important;
  cursor: pointer;
}

.paymodal-img-loading {
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #F6F6F6;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
  @extend .animated-background !optional;
}



.paymodal-car-title {
  margin-top: 20px !important;
  line-height: 20px !important;
  font-size: 20px !important;
  min-height: fit-content;
}

.card-paymodal {
  padding: 0px 16px 20px 16px !important;
}


.card-paymodal h6 {
  margin-top: 15px !important;
}

.card-paymodal-price {
  text-align: right !important;
}

.confirmation-box {
  text-align: center;
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
}

.loader-container {
  position: relative;
  /*top: 30%;
  left: 50%;*/
  text-align: center !important;
}

.paymodal-loader-box {
  width: 100px !important;
  height: 100px !important;
  border: 2.2em solid #1a222f !important;
  border-top-color: #f4af17 !important;
  border-radius: 100%;
  animation: 1s loader-05 linear infinite;
  position: relative !important;
  top: 30%;

}

.loader-box .loader-5 {
  border: 0px solid transparent !important;
}

.loader-text {
  font-size: 18px !important;
  color: #f4af17 !important;
  text-align: center !important;
}

.paymodal-loader-box .loader-5:before {

  display: none !important;
  opacity: 0;
  visibility: visible;
  transition: .3s ease;

}

.paymodal-loader-box {
  width: 80px !important;
  height: 80px !important;
  border: 1em solid #212529 !important;
  border-top-color: #f4af17 !important;
  border-radius: 100%;
  animation: 1s loader-05 linear infinite;
  position: relative;
  display: block;
}

// PAYMENT PAY PAGE

//SERVICES PAGE

.service-card-banner {
  border-radius: 0px 15px 0px 0px !important;
}

.services-bg-img {
  background: url('../../../assets/images/banner/maxpark-services.jpg');
  background-size: cover;
  min-height: 300px;
  border-radius: 15px 0px 0px 0px;
}

.bg-service-card {
  background-color: #101923 !important;
  color: #fff !important;

}

.service-card-same-height {
  height: 240px;
}

.gutter-x-0 {
  --bs-gutter-x: 0rem !important;
}


.f-400 {
  font-weight: 400;
}

.br-15 {
  border-radius: 15px !important;
}

.br-tl-15 {
  border-top-left-radius: 15px !important;
}

.br-tr-15 {
  border-top-right-radius: 15px !important;
}

.br-bl-15 {
  border-bottom-left-radius: 15px !important;
}

.br-br-15 {
  border-bottom-right-radius: 15px !important;
}


.services-card-main-left {
  border-top-left-radius: 15px !important;
}



/*************** CONTACT PAGE *******************/

.contact-container {
  margin-top: -300px;
  padding: 100px;
  position: relative;
  z-index: 999;
  background: #fff;
}

.default-according .card {
  border-radius: 10px;
}

.default-according .card-header {
  background: #f4f4f5 !important;
  padding: 10px !important;
  border-radius: 6px !important;
}

.btn-accordion {
  width: 100%;
  text-align: left;

}

// ADMIN STYLES


.table-avis-list {
  max-height: 60vh;
  overflow: scroll !important;
}


.search-bg-img {
  background: url('../../../assets/images/banner/maxpark-services.jpg');
  background-size: cover;
}

.company-settings-btn{
  border-top:1px solid #f8f8f8;
  border-bottom:1px solid #f8f8f8;
  position: absolute;
  bottom: 85px;
}


.settings-draggable > .react-kanban-board{

  border:1px solid red;

}

.settings-draggable > div > div.react-kanban-column{
  width: 100%;
}

.settings-draggable .react-kanban-board{

    /* overflow-y: hidden; */
    display: block;
    align-items: unset;
}

.settings-draggable .react-kanban-card{
  max-width: 100% !important;
  min-width: 100% !important;
  border-radius: 8px;
  background-color: transparent !important;
  padding: 20px;
  margin-bottom: 10px;
}


.settings-draggable .react-kanban-column {
  padding: 15px;
  border-radius: 8px;
  background-color:none !important;
  
}

/**=============================================================================================================================================================================================================================
  *
  *
  *RESPONSIVE DESIGN SPECIFIC
  *
  *
==============================================================================================================================================================================================================================**/
@media screen and (min-width: 350px) {
  .search-banner {
    // min-height:550px;
  }

  .landing-slider-headline {
    padding: 16px;
  }

  .landing-home-contain h2 {
    padding: 16px 16px 16px 16px;
  }

  .list-item {
    max-width: 285px;
  }


}

@media screen and (max-width: 600px) {

  //For Tablets
  .toggle-sidebar {
    display: block;
  }

  .navbar {
    display: none;
  }

  .landing-slider {
    height: 500px !important;

  }

  .searchbox-bg {
    min-height: 350px;
  }

  .quote-form {
    padding-top: 0px;
  }

  .quote-form-first-row {
    margin-top: 0px;
  }

  .section-nos-services {
    padding: 40px;
  }

  .footer-client {
    padding: 40px 16px 20px 16px;
  }
  


  /* Search Page */
  .paymodal-container {
    //top: 5%;
    // right: -1%;
  }

}

/* LOGIN  PAGE */
.login-card {
  background-color: rgba($secondary-color, 1) !important;
}



/* Admin SideBar Menu */


.page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .main-navbar .nav-menu>li .nav-link {
  border-radius: 5px !important;
  margin-top: 10px;
}

.page-main-header .main-header-right .nav-right .notification-dropdown li .media .userprofile-bg {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}

.logout-icon {
  color: #000;
}

.dashboard-main-nav {
  opacity: 1;
  width: 230px !important;
  transition: 0.3s ease;
}

.dashboard-mobile-nav-close {
  opacity: 0;
  width: 0px !important;

}

.img-search{
width: 75px !important;
margin: 0px;

}

.txt-search{
  display: flex;
  //position: relative;
  //left: 120px;
   //width: 60%;
}
.hideOverflow{
  overflow:hidden !important;
  height:90px !important;
}

.searchmark{
  padding: 0.1875em;
  background-color: #202020;
  color: rgba($primary-color, 1);
}

.text-capitalized{
  text-transform:capitalize;
}

.Toastify__toast--success {
  background: #fff!important;
}
.Toastify__toast--info {
  background: #fff!important;
}
.Toastify__toast--warning {
  background: #fff!important;
}
.Toastify__toast--error {
  background: #fff!important;
}
/***************    Dashboard TypeAhead Fix  ***********************************************************************************/
.rbt {
  width: 80% !important;

}

.rbt-input-main {}

.rbt-input-wrapper {}

.rbt-input-multi,
.rbt-input-wrapper,
.rbt.has-aux {
  display: flex;
  overflow: hidden;
}

.rbt-token {
  background-color: rgba(36, 105, 92, .1) !important;
  color: var(--theme-default) !important;
  display: flex;
  padding: 5px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.rbt-token .rbt-token-remove-button {
  top: -5px !important;
}

.rbt-token .rbt-close-content {
  position: absolute;
  top: -2px !important;
}

.rbt-token+.rbt-token {
  margin-left: 8px;
}

button.close span,
button[aria-label=Close] span {
  color: #898989;
  font-size: 24px;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-datepicker-wrapper {
  width: 80% !important;
  //width: auto !important;
  //min-width: fit-content !important;
}

.react-datepicker-wrapper div input {
  //min-width: 210px;
}

.react-datepicker__close-icon::after {
  background-color: rgba($primary-color, 1);
  position: relative;
  left: 30px;
}

/************************************************************************************************  *********************************/


@media screen and (min-width: 600px) {

  //For Tablets
  .paymodal-container {
    width: 400px;

  }

  .searchbox-bg {
    min-height: 300px;
  }

  .list-item {
    max-width: 465px;
  }

}

@media screen and (min-width: 768px) {

  //For Laptops
  .service-card-same-height {
    height: 340px;
  }

  .paycard-container {
    margin-top: 150px;
  }

  .searchbox-bg {
    min-height: 300px;
  }

  .list-item {
    max-width: 150px;
  }



}



@media screen and (max-width: 768px) {
  // Admin Search Page
  .searchOpen {
    width: 60px !important;
  }

  .animated-hide {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.6s, visibility 0.6s;
  }
  
  

}


@media screen and (max-width: 992px) {
  .toggle-sidebar {
    display: block;
  }

  .navbar {
    display: none;
  }


}

@media screen and (min-width: 992px) {

  //For Large Laptops
  .paymodal-container {}

  .searchbox-bg {
    min-height: 350px;
  }

  .payment-form {
    min-width: 500px !important;
  }

  .search-banner {
    //min-height:750px;
  }


  .list-item {
    max-width: 210px;
  }
}

@media screen and (min-width: 1280px) {

  //For Big TV's (HD Screens) 
  .paymodal-container {}

  .list-item {
    max-width: 270px;
  }
}

@media screen and (min-width: 1400px) {
  .paymodal-container {}

  .list-item {
    max-width: 330px;
  }
}

@media screen and (min-width: 1600px) {
  .paymodal-container {}

}


/* Animations */


.animated {
  -webkit-animation-duration: 50s;
  animation-duration: 50s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}


@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}


.animate-width {
  -webkit-transition: width 0.6s ease-in-out;
  -moz-transition: width 0.6s ease-in-out;
  -o-transition: width 0.6s ease-in-out;
  transition: width 0.6s ease-in-out;
}



/**=====================
  000. SCONCEPT Custom CSS End
==========================**/